import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import { SEO_KEYWORDS } from '../utils/Variables'

// STYLES
import { AboutPageContainer } from '../styles/Containers'
import { H1 } from '../styles/Headers'
import { Image } from '../styles/Images'

const profilePic =
  'https://res.cloudinary.com/dplvzflkc/image/upload/v1559648826/alexis_koajy6.jpg'

const AboutPage = () => (
  <Layout location="/about" title="Alexis Reese">
    <SEO title="About" keywords={SEO_KEYWORDS} />
    <AboutPageContainer>
      <div>
        <H1>About Alexis</H1>
        <p>
          Alexis Reese is an innovative Digital Entertainment Writer and On Air
          Talent Host at{' '}
          <a
            href="https://www.bet.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            BET.com
          </a>{' '}
          with a proven track record of developing creative content for TV
          industry leaders to increase social media engagement and readership.
          She is an insightful journalist that tells pithy stories that leave an
          impact on the audience. Alexis is currently seeking an On Air Talent
          or Digital Entertainment Writer career opportunity to execute digital
          marketing strategies that increase the organization's online presence.
        </p>
      </div>
      <Image src={profilePic} alt="Alexis Reese Profile Photo" />
    </AboutPageContainer>
  </Layout>
)

export default AboutPage
