import styled from 'styled-components'
import { MAX_WIDTH } from '../utils/MediaQueries'

export const Image = styled.img`
  width: ${props => props.width};
  display: block;
  margin: 0 auto;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

  &:hover {
    transform: scale(1.02);
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }

  /* @media (max-width: ${MAX_WIDTH}px) {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  } */
`
